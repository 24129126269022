body {
  background-color: #000000;
  font-family: 'Rockledge', sans-serif;
}

@font-face {
  font-family: 'Rockledge';
  src: url('./fonts/rockledge.ttf') format('truetype');
}

.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.candle-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.candle-image {
  width: 200px;
  height: 200px;
  border-radius: 15px;
  border: 2px solid white;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  object-fit: cover;
}

.candle-image:hover {
  transform: rotate(2deg);
}

.candle-image.selected {
  border: 2px solid red;
}

.candle-image.non-selected {
  filter: grayscale(100%);
}

.candle-image.no-click {
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .candle-image {
    width: 80px;  /* Smaller size for mobile */
    height: 80px; /* Smaller size for mobile */
  }
}


.scrolling-skulls-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
}

.skull-image {
  flex: 0 0 auto;
  width: 9%;
  border: 1px solid white;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.skull-image:hover {
  transform: scale(2);
}

.endcap {
  flex: 0 0 auto;
  width: calc(45% + 20px);
}

.image-overlay-container {
  position: relative;
  width: 400px;
}

.image-overlay-container:hover .overlay-text {
  display: flex;
  opacity: 0.7;
}

.overlay-text {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.95);
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  padding: 30px;
}

.description-text {
  color: #dbd68c;
  font-size: 14px;
}

.author-text {
  color: #dbaa72;
  font-size: 12px;
}

.blur-background {
  filter: blur(8px);
}

.button-transition {
  transition: background-color 0.2s, color 0.2s;
}

.button-transition.loading {
  background-color: gray;
  color: white;
  cursor: not-allowed;
}

@keyframes glowingEffect {
  0%, 100% { box-shadow: 0 0 5px #fff; }
  50% { box-shadow: 0 0 20px #fff; }
}

.glow-on-confirm {
  animation: glowingEffect 2s infinite;
}

.no-pointer {
  cursor: not-allowed;
}

.blur-when-busy {
  filter: blur(5px);
  opacity: 0.5;
}

.main-image-container.upgraded {
  animation: fadeIn 1s ease-in-out;
}

.candle-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

@keyframes burnFlame {
  0% {
    transform: scale(1);
    filter: brightness(100%) contrast(100%);
    opacity: 1;
  }
  25% {
    filter: brightness(120%) contrast(110%) sepia(0.3);
  }
  50% {
    filter: brightness(140%) contrast(120%) sepia(0.6);
  }
  75% {
    filter: brightness(160%) contrast(130%) sepia(0.9);
  }
  100% {
    transform: scale(0.95);
    filter: brightness(60%) contrast(150%) sepia(1) saturate(700%) hue-rotate(15deg);
    opacity: 0.2; /* Adjusted to match .low-quantity opacity */
  }
}

@keyframes smokeEffect {
  0%, 100% {
    opacity: 0;
    transform: translateY(0) scale(0.9);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-20px) scale(1.2);
  }
}

.burning {
  animation: burnFlame 3s forwards;
}

.low-quantity {
  opacity: 0.2;
}






@keyframes loading {
  0%, 100% {
    transform: scale(1) rotate(0deg);
    filter: brightness(0.8);
  }
  25% {
    transform: scale(1.05) rotate(3deg);
    filter: brightness(1);
  }
  50% {
    transform: scale(1.1) rotate(-3deg);
    filter: brightness(0.9);
  }
  75% {
    transform: scale(1.05) rotate(3deg);
    filter: brightness(1.1);
  }
}


.main-image-container.loading {
  animation: loading 2s infinite ease-in-out;
}

.main-image-container.inert {
    /* No styles defined here */
}

@keyframes floatEffect {
  0%, 100% {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  50% {
    transform: translateY(5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  }
}

@keyframes glowMythic {
  0%, 100% {
    box-shadow: 0 0 10px rgba(255, 140, 0, 0.7), 0 0 30px rgba(255, 140, 0, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 165, 0, 1), 0 0 40px rgba(255, 165, 0, 0.8);
  }
}

@keyframes glowEpic {
  0%, 100% {
    box-shadow: 0 0 10px rgba(147, 0, 147, 0.7), 0 0 30px rgba(147, 0, 147, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(128, 0, 128, 1), 0 0 40px rgba(128, 0, 128, 0.8);
  }
}

@keyframes glowRare {
  0%, 100% {
    box-shadow: 0 0 10px rgba(0, 229, 229, 0.7), 0 0 30px rgba(0, 229, 229, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 255, 255, 1), 0 0 40px rgba(0, 255, 255, 0.8);
  }
}

@keyframes glowUncommon {
  0%, 100% {
    box-shadow: 0 0 10px rgba(0, 102, 0, 0.7), 0 0 30px rgba(0, 102, 0, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 128, 0, 1), 0 0 40px rgba(0, 128, 0, 0.8);
  }
}

@keyframes sparkle {
  0%, 100% {
    opacity: 0.8;
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
}

.main-image-container.mythic {
  animation: floatEffect 3s infinite ease-in-out, glowMythic 4s infinite ease-in-out, sparkle 5s infinite alternate;
}

.main-image-container.epic {
  animation: floatEffect 3s infinite ease-in-out, glowEpic 4s infinite ease-in-out, sparkle 5s infinite alternate;
}

.main-image-container.rare {
  animation: floatEffect 3s infinite ease-in-out, glowRare 4s infinite ease-in-out, sparkle 5s infinite alternate;
}

.main-image-container.uncommon {
  animation: floatEffect 3s infinite ease-in-out, glowUncommon 4s infinite ease-in-out, sparkle 5s infinite alternate;
}

.main-image-container.common {
  animation: floatEffect 3s infinite ease-in-out, glowCommon 4s infinite ease-in-out;
}




.skull-image.no-click {
  cursor: not-allowed;
}

.ritual-complete-modal .modal-header {
  text-align: center;
  font-size: 2em;
}

.ritual-complete-modal .modal-body {
  text-align: center;
}

.ritual-complete-modal img {
  /* Additional styles if needed */
}

.modal-image {
  /* Your existing styles */
}

.responsive-modal-image {
  width: 80%; 
  height: 0; 
  padding-bottom: 80%; 
  object-fit: cover;
  position: relative;
}

.responsive-modal-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.ritual-complete-image {
  width: 60%; 
  height: auto; 
  max-width: 100%;
  aspect-ratio: 1 / 1;
  margin: 0 auto;
}


@media (max-width: 768px) {

  .date-container {
    text-align: center;
  }

  .modal-content {
    width: 70%;
    max-width: 90%;
    margin: auto;
  }

  .image-overlay-container {
    width: 60vw; /* Set width to 60% of viewport width */
    height: 60vw; /* Set height equal to the width to maintain aspect ratio */
    margin: 0 auto; 
  }

  .image-overlay-container img {
    width: 100%; /* Make image fill the container */
    height: 100%; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure image covers the area without distortion */
  }

  .responsive-modal-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay-text {
    display: none; 
  }

  .close-button {
    width: 100px; 
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px; 
  }

  .close-button-container {
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    margin-top: 10px; /* Space above the container */
  }

  .skull-image {
    width: 16%;
    margin: 1%;
  }


  






}
